import dayjs from "dayjs";

export const magazineData = [
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-0.png`,
        name: "2024年10月号刊",
        _id: "2024年10月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-1.png`,
        name: "2024年9月号刊",
        _id: "2024年9月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-2.png`,
        name: "2024年8月号刊",
        _id: "2024年8月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-3.png`,
        name: "2024年7月号刊",
        _id: "2024年7月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-4.png`,
        name: "2024年6月号刊",
        _id: "2024年6月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-5.png`,
        name: "2024年5月号刊",
        _id: "2024年5月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-6.png`,
        name: "2024年4月号刊",
        _id: "2024年4月号刊",
        preview: 3
    },
    {
        fileName: "magazine",
        filePath: "/Estetica_CN_84_Book_A_Digital_Version.pdf",
        cover: `/magazines/image-7.png`,
        name: "2024年3月号刊",
        _id: "2024年3月号刊",
        preview: 3
    },
];


export const newsMainData = [
    {
        cover: "/news/image-0.png",
        name: "Kemon 时装秀：创新与激情点亮马德里"
    },
    {
        cover: "/news/image-1.png",
        name: "Freelance Re: Source – 可持续发展工具包，助力个体美发专业人士"
    },
    {
        cover: "/news/image-2.png",
        name: "如何制作这款由UNEC设计的华丽辫子发髻"
    }
];


export const newsData = [
    {
        code: "00001",
        cover: "/news/image-3.png",
        title: "OMC Hairworld 2024:巴黎闪耀 全球美发大师",
        description: "OMC Hairworld 2024大赛在著名的MCB by Beauté Sélection期间举行,吸引了来自全 球各地的一些最有远见的发型师。",
        company: "OMC 美发展 2024",
        publishedDate: dayjs("2024-10-25"),
    },
    {
        code: "00002",
        cover: "/news/image-4.png",
        title: "Francesco 美发沙龙享受一个充满 庆祝、才艺和传统的夜晚",
        description: "Raphaël Perrier 呈现了一场精彩绝伦的美发 秀,将美发艺术提升到了新的高度,让观众 惊叹于所展示的创造力和技巧,",
        company: "英国美发行业大奖",
        publishedDate: dayjs("2024-10-23"),
    },
    {
        code: "00003",
        cover: "/news/image-5.png",
        title: "2024年最佳沙龙奖:庆祝世界顶 级沙龙设计",
        description: "OMC Hairworld 2024大赛在著名的MCB by Beauté Sélection期间举行、吸引了来自全 球各地的一些最有远见的发型师。",
        company: "OMC 美发照 2024",
        publishedDate: dayjs("2024-10-26"),
    },
    {
        code: "00004",
        cover: "/news/image-6.png",
        title: "Francesco 美发沙龙享受一个充满 庆祝、才艺和传统的夜晚",
        description: "Raphaël Perrier 呈现了一场精彩绝伦的美发 秀,将美发艺术提升到了新的高度,让观众 惊叹于所展示的创造力和技巧。",
        company: "英国美发行业大奖",
        publishedDate: dayjs("2024-10-23"),
    }
];


export const educationData = [
    {
        videoUrl: "example.com",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-0.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-1.png",
    },
    {
        videoUrl: "example.com",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-2.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-3.png",
    },
    {
        videoUrl: "example.com",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-4.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-5.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-6.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-7.png",
    },
    {
        videoUrl: "",
        title: "如何制作这款由UNEC设计的华丽辫子发髻",
        description: "UNEC (全国美发企业联盟) 一步步重现了这一华丽的编织发髻，并将其命名为 Fascination。",
        imageUrl: "/education/image-8.png",
    }
];