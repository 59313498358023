import { Button, Carousel, List } from 'antd'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../constants/Url';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { antdResponsive, showFormatDate } from '../utils/Utils';
import TextScroll from '../components/TextScroll';
import { useTranslation } from 'react-i18next';
import { newsData, newsMainData } from '../constants/TempData';

export default function NewsPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState("专业技巧");
    const [blogs, setBlogs] = useState([
        {
            cover: "",
            title: "",
            description: "",
            company: "",
            publishedDate: "",
        }
    ]);
    const [blogMain, setBlogMain] = useState([
        {
            name: "",
            cover: "",
        }
    ]);
    const [category, setCategory] = useState([
        {
            name: "",
            _id: "",
        }
    ]);

    useEffect(() => {
        fetchCategories();
        fetchMainBlog();
        fetchBlog();
    }, [])

    const fetchBlog = async () => {
        const res = newsData;

        setBlogs([...res])
    }

    const fetchCategories = async () => {
        const res = [];

        ["专业技巧", "突发新闻", "活动"].forEach((item) => {
            res.push({
                name: item,
                _id: item
            });
        })
        setCategory([...res])
    }

    const fetchMainBlog = async () => {
        const res = newsMainData;

        setBlogMain([...res])
    }

    const handleChangeCategory = (item) => {
        setSelectedCategory(item._id);
    }

    const handleViewDetail = (item) => {
        navigate(`/news/${item.code}`, { state: { news: item } });
    }

    return (
        <div className='esi-std-container'>
            <Carousel
                className='esi-std-white-space esi-news-main-carousel'
                responsive={antdResponsive({
                    xxs: {
                        slidesToShow: 1,
                    },
                    sm: {
                        slidesToShow: 2,
                    },
                })}
                slidesToShow={3}
                draggable
                autoplay
                slidesToScroll={1}
                dots={false}
                swipeToSlide
            >
                {blogMain.map((item) => {
                    return <div className='esi-news-card'>
                        <div className='overflow-hidden max-sm:rounded-[1rem]'>
                            <img className='esi-news-card-image' src={BASE_URL + item.cover} alt={item.name} />
                        </div>
                        <div className="text-start text-white md:text-black absolute left-[24px] bottom-[6px] md:relative md:left-0 md:bottom-0 esi-news-card-title max-w-[calc(100%-24px*2)] md:max-w-[100%] mt-3 line-clamp-2">
                            {item.name}
                        </div>
                    </div>;
                })}
            </Carousel>

            <TextScroll textButton={t('Latest news')} content={t('After 13 successful years in Spain, Thys Niermeyer is appointed as the new head of ghd North America')} />

            <div className='esi-std-white-space flex justify-center md:justify-start'>
                {
                    category.map(item => {
                        return <Button type='text' className={`esi-news-category ${selectedCategory === item._id && "active"}`} onClick={() => handleChangeCategory(item)}>
                            {item.name}
                        </Button>;
                    })
                }
            </div>

            <div className='esi-std-white-space'>
                <List
                    className="mt-4"
                    grid={{
                        gutter: { xs: 10, md: 20, lg: 30 },
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 2,
                        xl: 2,
                        xxl: 2,
                    }}  
                    pagination={false}
                    dataSource={blogs}
                    renderItem={(item) => (
                        <List.Item>
                            <div className="grid grid-cols-7" onClick={() => handleViewDetail(item)}>
                                <div className="col-span-3 overflow-hidden rounded-[0.5rem]">
                                    <img className="esi-sub-news-card-image" style={{ cursor: "pointer" }} src={`${BASE_URL}${item.cover}`} alt={item.fileName} />
                                </div>
                                <div className='col-span-4 px-3 flex flex-col justify-between'>
                                    <div className='flex flex-col gap-1'>
                                        <div className="esi-news-card-title max-w-[100%] line-clamp-2">{item.title}</div>
                                        <div className="esi-news-card-description max-w-[100%] line-clamp-2 sm:line-clamp-3">{item.description}</div>
                                    </div>
                                    <div className='mt-3 lg:mt-0 flex justify-between items-center'>
                                        <Button className='esi-secondary-button'>{item.company}</Button>
                                        <div className='esi-news-card-dob'>{showFormatDate(item.publishedDate)}</div>
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    )}
                ></List>
            </div>

            <div className='h-[40px]'></div>
        </div>
    )
}
