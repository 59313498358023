import React from 'react'
import { IconProp } from "./IconType";

// "white"
export default function AppleIcon(props = IconProp) {
    const { color, height, width, className, backgroundColor } = props;
    return (
        <svg className={className} width={width} height={height} style={{ color: color }} viewBox="0 0 17 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.41691 20C4.74919 19.9642 4.12085 19.6756 3.66161 19.194C3.11218 18.6702 2.62189 18.0887 2.19939 17.4599C1.5394 16.5234 1.01771 15.4983 0.650093 14.4157C0.234049 13.251 0.0151801 12.0262 0.00234058 10.7908C-0.0299808 9.57853 0.272973 8.38063 0.878399 7.32677C1.32182 6.56716 1.95429 5.93282 2.71547 5.4843C3.46822 5.03769 4.32722 4.79729 5.20454 4.78771C5.8659 4.82648 6.51581 4.97628 7.12655 5.2307C7.61396 5.44326 8.12579 5.59268 8.65143 5.67475C9.23124 5.55439 9.79779 5.37818 10.343 5.14863C10.9883 4.89897 11.6715 4.75915 12.3638 4.7351C12.4562 4.7351 12.5475 4.7351 12.6356 4.74562C14.1541 4.78876 15.5664 5.52744 16.4584 6.74488C15.7623 7.11383 15.183 7.66685 14.785 8.34246C14.3871 9.01807 14.1859 9.78981 14.204 10.5719C14.1971 11.1699 14.3181 11.7626 14.5592 12.3109C14.8003 12.8592 15.1559 13.3506 15.6026 13.7528C16.0093 14.1369 16.4829 14.4442 17 14.6609C16.8938 14.9766 16.7717 15.2817 16.6421 15.589C16.348 16.268 15.9851 16.9156 15.559 17.522C15.1561 18.1294 14.6871 18.6912 14.1605 19.1971C13.6801 19.6695 13.0398 19.9481 12.3638 19.979C11.7908 19.9538 11.2278 19.8206 10.7051 19.5865C10.1415 19.3432 9.53574 19.2099 8.92115 19.194C8.28921 19.2063 7.66555 19.3389 7.08408 19.5844C6.58185 19.8089 6.04529 19.9487 5.49655 19.9979L5.41691 20ZM8.76186 4.7351C8.68222 4.7351 8.60258 4.7351 8.52294 4.72563C8.50619 4.60038 8.49732 4.47421 8.49639 4.34787C8.53113 3.29682 8.94565 2.29309 9.66447 1.51944C10.0665 1.07482 10.5538 0.713908 11.098 0.457726C11.6051 0.199037 12.1593 0.043412 12.728 0C12.7439 0.137844 12.7439 0.272531 12.7439 0.400905C12.729 1.43544 12.3367 2.42966 11.6396 3.19987C11.302 3.64836 10.8695 4.0182 10.3724 4.28342C9.87523 4.54863 9.32553 4.7028 8.76186 4.7351Z" fill={color} />
        </svg>

    )
}
