import { List } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { BASE_URL } from '../constants/Url';
import AppDownloadModal from '../components/AppDownloadModal';
import MagazineModal from '../components/MagazineModal';
import { magazineData } from '../constants/TempData';

export default function MagazinePage() {
    const magazineRef = useRef();
    const appDownloadRef = useRef();
    const [dataSource, setDataSource] = useState([
        {
            fileName: "magazine",
            filePath: "ESI_Knowledge.pdf",
            cover: "/magazine.png",
            name: "2024年6月号刊"
        }
    ]);

    const handleShowMagazine = (item) => {
        magazineRef.current?.showModal(item);
    }

    const handleOpenDownloadApp = (unMountMagazine) => {
        appDownloadRef.current?.showModal();
        unMountMagazine();
    }

    const handleCloseDownloadApp = () => appDownloadRef.current?.closeModal();

    const fetchData = () => {
        const res = magazineData;

        setDataSource([...res])
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className='esi-std-container'>
            <List
                className="esi-std-white-space"
                grid={{
                    gutter: [25, 15],
                    xs: 2,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 4,
                }}
                pagination={false}
                dataSource={dataSource}
                renderItem={(item) => (
                    <List.Item>
                        <div className="text-center gap-3 flex flex-col">
                            <div
                                className="overflow-hidden"
                                onClick={() =>
                                    handleShowMagazine(item)
                                }
                            >
                                <img className="esi-card-image" style={{ cursor: "pointer" }} src={`${BASE_URL}${item.cover}`} alt={item.fileName} />
                            </div>
                            <div className="esi-card-title max-w-[100%] truncate">{item.name}</div>
                        </div>
                    </List.Item>
                )}
            ></List>
            <MagazineModal
                ref={magazineRef}
                handleCloseDownloadApp={handleCloseDownloadApp}
                handleOpenDownloadApp={handleOpenDownloadApp}
            />
            <AppDownloadModal ref={appDownloadRef} />
        </div>
    )
}
