import { Button, Carousel } from 'antd'
import React from 'react'
import { BASE_URL } from '../constants/Url'
import { PiHairDryerFill } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import BeautyIcon from '../assets/icons/Beauty';
import CleaningIcon from '../assets/icons/Cleaning';
import MenSalonIcon from '../assets/icons/MenSalon';
import PlayIcon from '../assets/icons/Play';

export default function HomePage() {
  const { t } = useTranslation();

  return (
    <div className='w-full'>
      <Carousel className='w-full esi-carousel-banner' draggable dots pauseOnDotsHover pauseOnHover autoplay>
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-1.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-2.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-3.png' />
        <img className='esi-banner' src={BASE_URL + "about-us/Banner.png"} alt='Carousel-Image-4.png' />
      </Carousel>

      <div className='esi-white-space'></div>

      <div className='esi-std-container'>

        {/* about us */}
        <div className='md:grid grid-cols-5 gap-3 flex flex-col-reverse'>
          <div className='max-w-[640px] pt-5 pb-5 sm:pb-10 col-span-3 flex flex-col gap-3'>
            <div className='esi-content-title'>
              {t("Content About Us")}
              <div className='esi-secondary-line'></div>
            </div>

            <div className='flex flex-col gap-4 mt-3'>
              <div className='esi-content'>{t("There are many variations of passages Ipsum available but the majority have suffered alteration.")}</div>
              <div className='esi-content'>{t("in that some injected is humour or randomised words the which don't look slightly a believable. If you are Lorem and Ipsum you isn't anything embarrassing.")}</div>
            </div>
            <div className='mb-0 mt-auto'>
              <Button className='esi-std-button'>
                {t("Contact Us")}
              </Button>
            </div>
          </div>
          <div className='col-span-2'>
            <img src={BASE_URL + "/about-us/About Us.png"} className='esi-image' alt='ESI About Us' />
          </div>
        </div>

        <div className='esi-white-space'></div>

        <div className='md:grid grid-cols-5 gap-3 flex flex-col'>
          <div className='col-span-2'>
            <img src={BASE_URL + "/about-us/What We Do.png"} className='esi-image' alt='ESI What we do' />
          </div>
          <div className='max-w-[640px] pt-5 px-0 sm:px-[70px] pb-0 sm:pb-10 col-span-3 flex flex-col gap-3'>
            <div className='esi-content-title'>
              {t("What We Do")}
              <div className='esi-secondary-line'></div>
            </div>

            <div className='esi-content mt-3 mb-3'>{t("There are many variations of passages of Lorem Ipsum available but the majority have randomised words which look even slightly believable.")}</div>

            <div className='mb-0 mt-auto flex justify-between'>
              <div className='flex flex-col gap-3 items-center'>
                <BeautyIcon className='esi-icons' color='#CABDFF' backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("HAIR-NEWS")}</div>
              </div>

              <div className='flex flex-col gap-3 items-center'>
                <MenSalonIcon className='esi-icons' color="#AFC6FF" backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("TRENDS")}</div>
              </div>

              <div className='flex flex-col gap-3 items-center'>
                <CleaningIcon className='esi-icons' color="#FFD88D" backgroundColor="#2C2B46" />
                <div className='esi-icons-content'>{t("EDUCATION")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='esi-white-space'></div>

        <div className='relative'>
          <div className='esi-play-icon-wraper'>
            <div className='esi-play-icon-container relative'>
              <div className='esi-play-icon-blur'></div>
              <PlayIcon className='esi-play-icon' color='white' />
            </div>
          </div>
          <img src={BASE_URL + "/about-us/video.png"} className='esi-responsive-iframe' />
        </div>

        <div className='esi-white-space'></div>
      </div>
    </div>
  )
}
