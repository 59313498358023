import React from 'react'
import { IconProp } from "./IconType";

// "white"
export default function AndroidIcon(props = IconProp) {
    const { color, height, width, className, backgroundColor } = props;
    return (
        <svg className={className} width={width} height={height} style={{ color: color }} viewBox="0 0 17 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.14405 15.8838C3.14405 16.4322 3.56694 16.8799 4.08726 16.8799H5.16553V19.7016C5.16553 20.4164 5.70799 21 6.37444 21C7.04311 21 7.58335 20.4188 7.58335 19.7016V16.8775H9.46535V19.6993C9.46535 20.4141 10.0078 20.9977 10.6743 20.9977C11.3429 20.9977 11.8832 20.4164 11.8832 19.6993V16.8775H12.9614C13.4818 16.8775 13.9047 16.4299 13.9047 15.8815V6.61939H3.14405V15.8838ZM11.0507 1.8196L12.0448 0.296234C12.1024 0.207175 12.0891 0.0876494 12.0116 0.0290583C11.9341 -0.0271892 11.8234 0.000934672 11.7658 0.0899932L10.734 1.6743C10.0543 1.39072 9.29708 1.23369 8.50221 1.23369C7.70734 1.23369 6.95011 1.39072 6.27038 1.6743L5.2386 0.0923368C5.18103 0.00327828 5.07033 -0.0271891 4.99283 0.0314021C4.91534 0.0876496 4.90206 0.204832 4.95962 0.298578L5.95376 1.82195C4.37288 2.60004 3.26582 4.07419 3.12412 5.79677H13.8847C13.7386 4.07185 12.6293 2.59769 11.0507 1.8196ZM6.23053 4.22184C5.90284 4.22184 5.63493 3.9406 5.63493 3.5914C5.63493 3.24454 5.90062 2.96096 6.23053 2.96096C6.55822 2.96096 6.82612 3.2422 6.82612 3.5914C6.82391 3.9406 6.55822 4.22184 6.23053 4.22184ZM10.8447 4.22184C10.5171 4.22184 10.2491 3.9406 10.2491 3.5914C10.2491 3.24454 10.5148 2.96096 10.8447 2.96096C11.1724 2.96096 11.4403 3.2422 11.4403 3.5914C11.4381 3.9406 11.1724 4.22184 10.8447 4.22184ZM15.7911 6.58189C15.1224 6.58189 14.5822 7.16312 14.5822 7.88027V12.9519C14.5822 13.6667 15.1246 14.2503 15.7911 14.2503C16.4597 14.2503 17 13.6691 17 12.9519V7.87793C17.0022 7.16077 16.462 6.58189 15.7911 6.58189ZM1.20891 6.58189C0.540244 6.58189 0 7.16312 0 7.88027V12.9519C0 13.6667 0.542459 14.2503 1.20891 14.2503C1.87757 14.2503 2.41782 13.6691 2.41782 12.9519V7.87793C2.41782 7.16077 1.87536 6.58189 1.20891 6.58189Z" fill={color} />
        </svg>
    )
}
